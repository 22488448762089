.dropzone {
  width: 100%;
  height: 200px;
  border: 2px dashed #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
}

.imageUploader {
  border-radius: 5px;
  height: auto;
}



.previewContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.preview {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cross {
  width: 12px;
  height: 12px;
  position: relative;
}

.cross::before,
.cross::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

.reachedLimit{
  
}

@media (max-width: 700px) {
  .previewContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }

  .preview {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

}