.priceContent {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    align-items: flex-start;
    

}

.priceContent label {
    font-size: 18px;
    font-weight: 600;
    align-self: flex-start;
}

.priceContent div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: center;

}

.priceContent div input {
    width: 45%;
    padding: .2rem;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}

.priceContent div span {
    font-size: 14px;
    font-weight: 500;
}

.button {
    width: auto !important;
}