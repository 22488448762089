.carItem {
    display: flex;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, .15);
    flex-direction: column;
    transition: all 0.3s;
    text-decoration: none;
    overflow: hidden;
    position: relative;
}

.carItem:hover {
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.308);
}

.premium {
    position: absolute;
    z-index: 101;
    font-size: 25px;
    right: 2%;
    top: 2%;
    color: #fff;
    background-color: #3861fb;
    border-radius: 100px;
    padding: 1px;
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.308);
}

/* 
@media (max-width: 1550px) {

}

@media (max-width: 1150px) {

}

@media (max-width: 780px) {
  
} */

.firstBlock {
    height: 250px;
    object-fit: contain;
    width: 100%;
}

.secondBlock {
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.carDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0px;
    border-bottom: 0.2px solid #9e9e9e69;
}

.carDetails div:last-child {
    font-weight: 600;
    text-align: flex-start;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.secondBlock ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 5px;
    list-style: none;
    font-size: 14px;
    align-items: flex-start;
    padding-left: 0;
}


.secondBlock ul li {
    display: flex;
    gap: 5px;
    list-style: none;
    font-size: 14px;
    align-items: center;
    padding-left: 10px;
    /* color: #686767; */
}


.year {
    display: inline-block;
    font-weight: 500;
    background-color: #b5abab1d;
    border-radius: 10px;
    padding: 5px;
    font-size: 13px;
}

.brand {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    gap: 8px;
    height: 30px;
    margin: 5px;
    font-size: 15px;
}

.resetSearch {
    background-color: #ffffff;
    color: #3861fb;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}

.resetSearch:hover {
    text-decoration: none;
}

.price {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    gap: 8px;
    margin: 5px 0px;
    font-size: 17px;
    border-top: 0.2px solid #9e9e9e69;
}

.priceUsd {
    color: #676767;
    /* Color gris claro */
    font-size: 15px;
    /* Tamaño de fuente más pequeño */
}

.deleteButton {
    background-color: #ff3b3b !important;
    color: #fff !important;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    font-weight: bold;
    transition: all 0.3s;
    flex-grow: 1;
    /* Take up available space */
}

.deleteButton:hover {
    color: #fff !important;
    background-color: darkred;
}

.deleteButton.clicked {
    background-color: crimson;
}


.infoSinpeButton {
    background-color: #3861fb !important;
    color: #fff !important;
}

.infoSinpeButton:hover {
    color: #fff !important;
}



.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.confirmation h2 {
    margin-bottom: 10px;
}

.confirmationButtons button {
    margin-right: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure the modal is above other elements */
    backdrop-filter: blur(5px);
    /* Apply blur effect to the background */
    overflow-y: auto;
    /* Enable vertical scrolling within the modal */
}

.buttonContainer {
    display: flex;
    width: 100%;
    padding: 3px;
    justify-content: space-between;
    gap: 5px;
}

.buttonContainer button {
    height: 3rem;
    flex: 1;
    font-size: 13px;
    border-radius: 5px;
    background-color: #efefef;
    color: #000;
    transition: all 0.2s;
    border: none;
    font-weight: 600;
}



.buttonContainer button:hover {
    background-color: #efefef;
    transform: translateY(-2px);
}

.modalHeader {
    text-align: center;
}

.modalContent {
    background-color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.modalOptions {
    text-align: left;
}

.modalOptions p {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modalOptions label {
    margin-left: 10px;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.modalButtons button {
    flex-grow: 1;
    margin-right: 10px;
}

.disabledButton {
    opacity: 0.5;
    pointer-events: none;
}

.deleteConfirmButton {
    background-color: #ff3b3b;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    font-weight: bold;
    transition: all 0.3s;
    border-radius: 5px;
}

.deleteConfirmButton:hover {
    background-color: darkred;
}

.goBackButton {
    background-color: #000;
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    /* font-weight: bold; */
    transition: all 0.3s;
    border-radius: 5px;
}

.goBackButton:hover {
    background-color: #0000007c;
}


@media (max-width: 750px) {

    .modalContent {
        width: 90%;
    }

    .buttonContainer {
        padding: 10px;
        gap: 5px;

    }
}