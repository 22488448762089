/* .container {
    height: 100vh; 
    top: 206px;
    height: calc(100vh); 
    width: 375px;
    overflow: auto;
    padding: 1rem;
} */



.filter {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1100px) {
    .filter {
        max-width: 90%;
        /* position: absolute; */
        margin: 0 auto;
        /* z-index: 1000 !important; */
        background-color: #fff;
    }
}

.reset {
    width: 3.5rem;
    border: none;
    color: #3861fb;
    font-weight: 600;
    background-color: transparent;
}

.filterOption {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.locationItems {
    display: flex;
    width: 90%;
    margin: 0 auto;
    text-align: flex-start;
    gap: .8rem;
}

.locationItems div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 45%;
}

.locationItems label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.74);
}

.locationItems select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}

.filterColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    gap: .8rem;
}


.separator {
    display: block;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}

.filterOption label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.74);
}

.filterOption select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #dad8d8;
    width: 40%;
}

.filterOption select option {
    margin: 10px;
}

.rangeFilter {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.rangeFilter label {
    font-size: 16px;
    font-weight: 600;
    align-self: flex-start;
}

.rangeFilter div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: center;

}

.rangeFilter div input {
    position: relative;
    width: 45%;
    padding: .2rem;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}

.rangeFilter div span {
    font-size: 14px;
    font-weight: 500;
}

.colorDisplayer {
    display: block;
    width: 1rem;
    border: 1px solid #dad8d8;
    height: 1rem;
    border-radius: 1000px;
}

.colorItem {
    display: flex;
    align-items: center;
}