.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.item {
  padding: 10px;
  text-align: start;
}

.item p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.gray {
  color: #727272;
}

.separator {
  grid-column: 1 / -1;
  border-bottom: 1px solid #ccc;
  margin: 4px
}

.captchaButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9px;
}


.corregirDatos {
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  width: 50%;
  padding: 0.2rem;
  background-color: #2e2e2e;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add transparent border to maintain layout */
  border: 2px solid transparent;
}

.corregirDatos:hover {
  background-color: #fff;
  color: #2e2e2e;
  border: 2px solid #2e2e2e;
}

.buttonContainer {
  display: flex;
  gap: 5px;
  height: 100%;
}


.enVenta {
  border: none;
  border-radius: 5px;
  width: 90%;
  padding: 1rem;
  /* Increase the width as per your requirement */
  background-color: #3861fb;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  /* Prevent text from breaking into multiple lines */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Display ellipsis (...) for truncated text */
}

.enVenta:not(:disabled):hover {
  background-color: #fff;
  color: #3861fb;
  border: 2px solid #3861fb;
}

.enVenta:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.editBtn {
  background-color: #181818;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 15px;
}

.editBtn:hover {
  background-color: rgba(24, 24, 24, 0.747)
}


.editBtn span {
  font-size: 18px;
}

.reviewContainer {
  display: grid;
  padding: 1rem;
  grid-template-columns: 60% 30%;
  max-width: 1600px;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
}

.planContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.planDetails {
  text-align: start;
  width: 100%;
}

.planDetails p {
  color: #727272;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 8px 0 8px 0;
}

.list li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.list li p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.carReview {
  height: 100%;
  text-align: start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review {
  width: 60%;
  min-width: 500px;
  max-width: 600px;
  margin: 2rem auto 0 auto;
}

@media (max-width: 700px) {

  .reviewContainer {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .review {
    width: 80%;
    min-width: 200px;
  }

}