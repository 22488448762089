@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto' !important;
}

html {
  font-family: 'Roboto' !important;
}

code {
  font-family: 'Roboto' !important;
}


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 38px;
  font-weight: 600;
}

h2 {
  font-size: 28px;
}


h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

h5 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}


h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

p {
  font-weight: 400;
  font-size: 15px;
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

select {
  /* Para anular los estilos predeterminados del navegador */
  background-color: transparent;
  /* Puedes establecer el color de fondo que desees */
}

svg {
  display: inline;
}

img {
  display: inline;
}

@media (max-width: 800px) {
  h1 {
    font-size: 2rem;
    font-weight: 600;
  }
}