.title {
  width: 100%;
  border-bottom: 1px solid rgb(221, 221, 221) !important;
}

.title h4 {
  display: block;
  border-radius: 5px;
  width: fit-content;
  padding: 1rem 2rem;
  /* box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.15); */
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.carContainer {
  /* background-color: #f2f2f2; */
  border: 1px solid rgb(221, 221, 221) !important;
  border-radius: 10px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.subtitleVendido {
  font-size: 30px;
  color: white;
  background: linear-gradient(to bottom, #6e6e6e, #6e6e6e);
  /* border-radius: 10px; */
  /* line-height: 1; */
}

.subtitle_text {
  /* color: rgb(98, 98, 98) ; */
  /* margin-left: 1em; */
}

.insideContainer {
  display: flex;
  flex-direction: row;
}

.listItem {
  width: 100%;
  padding: 4px 6px;
  flex: 0 0 25%;
  list-style: none;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

/* Exactamente igual a ListItem, pero con disable */
.listItemBlock {
  width: 100%;
  padding: 4px 6px;
  flex: 0 0 25%;
  list-style: none;
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.carListLoader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* margin: 2px; */
}

.skeletonMobile {

  margin: 5px;
  margin-top: 20px;

}