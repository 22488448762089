.content {
    text-align: start;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 1rem;
    /* margin-top: 20px; */
}


.carInformation {
    min-width: 100%;
    border-top: 1px solid rgb(221, 221, 221);
    padding-top: 1rem;
}

.priceTitle {
    min-width: 100%;
    border-top: 1px solid rgb(221, 221, 221);
}

.dealerProfile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.dealerProfile span {
    font-size: 30px;
}

.dealerProfile p {
    cursor: pointer;
    font-size: 15px;
    border-bottom: 2px solid #000;
    font-weight: 600;
    width: fit-content;
}




.carInformationGeneral {
    min-width: 100%;

    /* border-top: 1px solid rgb(221, 221, 221); */
    /* padding-top: 1rem; */
}

.carInfoContent {
    display: flex;
    gap: 6rem;
}

.carInfoContent ul {
    margin-top: 10px;
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.carInfoContent ul li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.item {
    display: flex;
    flex-direction: column;
}

.item span {
    font-size: 16px;
    font-weight: 400;
}

.item p {
    font-size: 17px;
    font-weight: 500;
}

.icon {
    font-size: 25px;
}


.features {
    border-top: 1px solid rgb(221, 221, 221);
    padding-top: 1rem;
}

.features ul {
    padding-left: 0px;
    list-style: none;
    margin-top: 30px;
}

.featureItem {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-top: 1px solid rgb(221, 221, 221);
}

.notes {
    border-top: 1px solid rgb(221, 221, 221);
    border-bottom: 1px solid rgb(221, 221, 221);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.notes p {
    margin-top: 5px;
}

@media (max-width: 800px) {
    .carInfoContent {
        justify-content: center;
        font-size: 15px;
        flex-direction: column;
        gap: 0rem;
    }

    .content {
        gap: 0px;
    }

}

.price {
    margin-top: -5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(221, 221, 221);
    opacity: 0;
    transform: translateY(20px);
    /* Inicialmente, mueve el contenido hacia abajo */
    animation: fadeInUp 0.3s ease-out 0.3s forwards;
    /* Agrega una animación */
}

.price h3 {
    font-size: 23px;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        /* Hacia el final de la animación, hace que el contenido sea visible */
        transform: translateY(0);
        /* Mueve el contenido a su posición original */
    }
}

.priceOtherCurrency {
    color: #676767e5;
    font-size: 20px !important;
    font-weight: 400;
}

.greyText {
    color: '#555' !important;
    font-weight: 300 !important;
    font-size: 15px !important;
}

.showBtn {
    display: flex;
    align-items: center;
    color: #3861fb;
    font-size: 16px;
    gap: 8px;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: none;
    border-top: 1px solid rgb(221, 221, 221);
    background-color: transparent;
    cursor: pointer;
}

.goBackButton {
    background-color: #000;
    border: 1px solid transparent;
    /* Borde transparente por defecto */
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    /* Agrega la transición para el cambio de color del borde */
}

.goBackButton:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.goToInstaButton {
    background: linear-gradient(135deg, #833ab4, #fd1d1d);
    /* background-color: #000; */
    border: 1px solid transparent;
    /* Borde transparente por defecto */
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    /* Agrega la transición para el cambio de color del borde */
}

.goToInstaButton:hover {
    background: #fff;
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.goToWhatsAppButton {
    background-color: #075e54;
    border: 1px solid transparent;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.goToWhatsAppButton:hover {
    background: #fff;
    background-color: #fff;
    color: #000;
    border-color: #000;
}

.link {
    text-decoration: none !important;
    /* Remove underline */
    color: inherit !important;
    /* Inherit color from parent element */
}

.linkSocial {
    text-decoration: underline !important;
    /* Añadir subrayado */
    color: black !important;
    /* Cambiar color del texto a negro */
}