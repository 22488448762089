.container {
    margin: 1rem 2rem;
    flex-grow: 1;
    display: block;
}

.flexContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* Automatically adjust columns */
    grid-gap: 10px;
    /* Gap between grid items */
    list-style: none;
    /* Remove default list styles */
    padding: 0;
}

.listItem {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 4px 6px;
    list-style: none;
}


@media (max-width: 1550px) {
    .listItem {
        padding: 4px 6px;
        flex: 0 0 25%;
        list-style: none;
    }

    .flexContainer {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 1200px) {
    .listItem {
        max-width: 90%;
        flex-direction: column;
        flex-grow: 1;
    }


}

@media (max-width: 550px) {
    .listItem {
        /* width: 100%; */
        flex-direction: column;
        flex: 0 0 80%;
        flex-grow: 1;
    }
}

@media (max-width: 900px) {
    .flexContainer {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .container {
        margin: 1rem 0;
    }
}