.google_button {
  background: #fff;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
  box-shadow: rgba(0,0,0,0.4) 1px 1px 5px 0;
  border-color: transparent;
  text-align: center;
  border-radius: 3px;
  width: 99%;
  height: 39px;
  padding-top: 0;
  margin-bottom: 2px;
  display: block;
  text-decoration: none;
  -webkit-transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
  transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
}

.google_button:hover {
  background-color: #f0f0f0;
  text-decoration: none;
}

.google_button:active {
  background: #eee;
  border: 2px solid #b7bec1;
}

