 .planDescription {
     text-align: initial;
 }

 .planName {
     font-size: xx-large;
 }

 .pricing {
     width: 1000px;
     padding: 3rem 5rem;
     display: grid;
     grid-template-columns: repeat(2, minmax(0, 1fr));
     gap: 1rem;
     margin: 0 auto;
 }

 .pricingItem {
     max-width: 400px;
     display: flex;
     align-items: center;
     justify-content: space-around;
     flex-direction: column;
     min-height: 600px;
     /* height: fit-content; */
     box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
     border-radius: 5px;
     padding: 1rem 2rem;

 }

 .content {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     text-align: flex-start;
     padding: 0.1rem;
     gap: 1rem;
 }

 .content h2 {
     font-size: 40px;
     font-weight: 800;
 }

 .content h2 span {
     font-size: 35px;
     font-weight: 700;
 }

 .smallText {
     color: rgb(102 102 102/1);
     font-size: 16px !important;
     font-weight: 400 !important;
 }

 .list {
     text-decoration: none;
     color: rgba(102, 102, 102, 0.904);
     text-align: initial;
 }

 .list a {
     text-decoration: none;
     /* Esto evita cualquier subrayado predeterminado */
     border-bottom: 1px solid rgba(102, 102, 102, 0.904);
     color: rgba(102, 102, 102, 0.904);
     /* Asegura que el color del texto sea el deseado */
 }

 .icon {
     color: rgb(3, 175, 3);
     font-size: 18px;
 }

 .iconGrey {
     color: rgb(60, 60, 60);
     font-size: 18px;
 }

 .btnContainer {
     height: 100%;
     width: 100%;
     display: flex;
     align-items: center;
     /* Cambia align-items a center */
     justify-content: center;
 }

 .btn {
     border: none;
     font-weight: 500;
     border-radius: 5px;
     padding: 8px 2rem;
     width: 80%;
     /* background-color: #3861fb; */
     background-color: #000;
     color: #fff;
     transition: all 0.3s;
 }

 .premium {
     background: #000;
     color: #fff;
 }

 .premiumIcon {
     z-index: 101;
     font-size: 35px;
     right: 2%;
     top: 2%;
     color: #fff;
     background-color: #3861fb;
     border-radius: 100px;
     padding: 3px;
     box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.308);
     transition: all 0.3s;
 }


 .premiumIcon:hover {
     transform: translateY(-2px);
 }

 .btn:disabled {
     background-color: #3861fb;
     /* Use a muted gray color for disabled background */
     cursor: not-allowed;
     /* Change cursor to indicate it's not clickable */
     opacity: 0.6;
     /* Reduce opacity to indicate it's disabled */
 }

 .btn:hover {
     transform: translateY(-2px);
 }

 @media (max-width: 980px) {
     .pricing {
         width: 100%;
         display: flex;
         flex-direction: column;
     }

     .pricingItem {
         height: fit-content;
         width: 100%;
         margin: 0 auto;
     }
 }

 @media (max-width: 700px) {
     .pricing {
         width: 100%;
         padding: 1rem 2rem;
         display: flex;
         flex-direction: column;
     }

     .pricingItem {
         display: flex;
         align-items: center;
         flex-direction: column;
         min-height: 600px;
         width: 95%;
     }
 }