.carItem {
    width: 100%;
    height: 155px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 12px;
    /* max-width: 400px; */
}

.firstBlock {
    width: 260px;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 12px 0px 0px 12px;
}

.secondBlock {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 10px 10px;
    gap: 5px;
    justify-content: space-between;
}

.secondBlock ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.secondBlock ul li {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.brand {
    font-weight: 600;
    font-size: 18px;
    text-align: start;
    overflow-y: hidden;
    height: 25px;
}

.price {
    font-weight: 600;
    font-size: 16px;
}

.showMore {
    border: 1px solid rgb(179, 179, 179);
    font-size: 14px;
    color: rgb(179, 179, 179);
}


@media (max-width: 780px) {

    .firstBlock {
        width: 180px;
    }

    .secondBlock {
        /* flex: 1; */
    }
}