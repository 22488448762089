.popUp {

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    bottom: 5px;
    border-radius: 5px;
    background-color: #fff;
    height: 30px;
    width: 225px;
    min-width: fit-content;
    border: 1px solid #000;
    gap: 5px;
    cursor: pointer;


}

.img {
    object-position: center;
    object-fit: scale-down !important;
    background-color: #333A41;
    max-height: fit-content;
}

.imgCover {
    object-position: center;
    object-fit: cover !important;
    background-color: #333A41;
    max-height: fit-content;
}