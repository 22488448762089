.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.title {
  margin-top: 10px;
}

.dot {
  animation: dotBlink .5s linear infinite alternate;
}

@keyframes dotBlink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  margin-top: 10px;
  animation: titleBlink 0.9s linear infinite alternate;
}

@keyframes titleBlink {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}