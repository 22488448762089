.footer {
    /* left: 0;
    bottom: 0;
    bottom: 0; */
    position: relative;
    bottom: 0;
    left: 0;
    justify-items: end;
    justify-self: end;
    margin-top: 5rem;
    height: 15rem;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    background-color: rgb(247, 247, 247);
    padding-top: 2rem;
}

.content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: start;
    padding-bottom: 1rem;
}

.content div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.media {
    display: flex;
    font-size: 35px;
    gap: .5rem;
    list-style: none;
    color: #1c1c1c;
}

.media li a {
    color: inherit;
    /* Use the parent color for links */
    text-decoration: none;
}

.bottom {
    width: 70%;
    margin: 0 auto;
    border-top: 1px solid #e5e5e5;
    padding-top: 1rem;
    padding-top: 1rem;
    text-align: start;
    margin-bottom: 50px;
}

@media (max-width: 1024px) {
    .content {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 1.5rem;
        height: fit-content;
    }

    .content div {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .bottom {
        height: 4rem;
    }

    .footer {
        height: 100%;
    }
}

.link {
    text-decoration: none !important;
    /* Remove underline */
    color: inherit !important;
    /* Inherit color from parent element */
}

.media li a {
    text-decoration: none !important;
    /* Remove underline */
    color: inherit !important;
    /* Inherit color from parent element */
}