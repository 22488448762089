@charset 'UTF-8';
/* Slider */


/* Icons */
/*@font-face*/
/*{*/
/*    font-family: 'slick';*/
/*    font-weight: normal;*/
/*    font-style: normal;*/

/*    src: url('./fonts/slick.eot');*/
/*    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');*/
/*}*/
/* Arrows */
.slick-prev {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    left: 1%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -100%);

    cursor: pointer;

    color: transparent;
    border: none;
    background-color: transparent;
    outline: none;
}

.slick-next {
    font-size: 0;
    line-height: 0;
    z-index: 100;
    position: absolute;
    top: 50%;
    right: 1%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -100%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    /*font-family: 'slick';*/
    font-size: 20px;
    line-height: 1;

    opacity: .95;
    color: rgb(0, 0, 0);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* .slick-prev {} */

/* [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
} */

.slick-prev:before {
    content: '❮';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    background-color: #ffffffb6;
    font-size: 15px;
}

[dir='rtl'] .slick-prev:before {
    content: '❯';
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #ffffffb6;
    font-size: 15px;
}

/* .slick-next {
    right: -25px; 
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
} */

.slick-next:before {
    content: '❯';
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #ffffffb6;
    font-size: 15px;
}

[dir='rtl'] .slick-next:before {
    content: '❮';
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border-radius: 100px;
    background-color: #ffffffb6;
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}


.slick-dots {
    display: inline-block;
}

.slick-dots li {
    /* position: relative; */
    display: none;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    display: flex;


    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-size: 40px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}