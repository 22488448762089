.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6rem 2rem;
    height: 100%;
    flex-direction: column;
    min-width: 400px;
    min-height: 400px;
    position: relative;
    gap: 1rem;
}

.closeBtn {
    position: absolute;
    background-color: transparent;
    border: none;
    font-size: 20px;
    right: 3%;
    top: 3%;

}

.title {
    align-self: center;
    font-size: 16px;
    width: fit-content;

}

@media (max-width: 700px) {

    .container {
        min-width: 100px;
    }
}