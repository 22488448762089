.block {
    display: relative;
    background-color: #000000af;
    height: 100%;
    width: 100%;
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.btn {
    width: 200px;
    height: 40px;
    margin: 0 auto;
    margin-top: 2rem;
    display: block;
}

.inputBlock {
    margin-bottom: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.inputBlock input {
    padding: 5px 12px;
    border-radius: 3px;
    border: 1px solid #efefef;
}

.inputBlock input:active {
    padding: 5px 12px;
    border-radius: 3px;
    border: 1px solid #efefef;
}

.smallText {
    font-size: 13px;
    margin-top: 15px;
    font-weight: 600;
}

.icon {
    color: #0059ff;
    font-weight: bold;
    font-size: 50px;
}

.buttonFb {
    color: #fff;
    background-color: #4267B2;
    height: 100%;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    border-radius: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;
}

.authContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 12%;
    margin-right: 12%;
}



@media (max-width: 700px) {

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
    }

    .block {
        height: 100%;
        width: 100%;
    }

    .container {
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 9999999;
        top: 0;
        left: 0;
    }

}