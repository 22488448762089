.container {
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 25px;
    /* margin-top: -70px; */
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0px 0px 34px 1px rgb(0 0 0 / 10%);
    background-color: #fff;
}


@media (max-width: 800px) {

    .container {
        box-shadow: none;
        border: none;
        padding: 10px 15px;
    }

}