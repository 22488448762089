.button {
    display: flex;
    align-items: center;
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    background-color: transparent;
    height: 100%;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}