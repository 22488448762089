.container{
    padding: 45px;
    display: flex;
    max-width: 1120px;
    margin: 0 auto;
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.innerContent{
    display: flex; 
    gap: 2rem;
}

.title{
width: 100%;
text-align: start;
margin:  2px 0 5px 0;  
}

.imgContainer{
height: auto;
width: 100%;
display: block; 
margin:0 auto;
border-radius: 5px;
}

.imgContainer img{
    height: 100%;
    width: 100%;
    object-fit:cover;
    border-radius: 5px;
}

.smallContainer{
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.185);
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    padding-left: 20px;
    text-align: start;
}

.smallContainer h4{
    font-size: 16px;
    font-weight: 400;
    color: #494949;
}

.smallContainer p{
    font-size: 22px;
    font-weight: 600;
}

.smallContainer ul{
   padding-left: 0px;
    list-style: none;
    text-align: start;
    
}

.contact{
    display: block;
    border:1px solid rgb(221, 221, 221);
    border-radius: 5px;
    height: 300px;
    width: 50%; 
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;;
}

.contact span{
    font-size: 14px;
    color:#494949;
    font-weight: 300px;
}


.containerNotFound {
    padding: 45px;
    display: flex;
    max-width: 1120px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.contentNotFound {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
}

.titleNotFound {
    width: 100%;
    text-align: center;
    margin: 5px 0 20px 0;
    font-size: 42px;
    font-weight: bold;
}
 .imgContainerNotFound {
     height: auto;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 5px;
     margin-top: 20px;
     /* margin-bottom: 10px; */
 }

 .imgContainerNotFound img {
     max-width: 60%;
     max-height: 60%;
     object-fit: cover;
     border-radius: 5px;
 }

 /* .button {
     display: inline-block;
     padding: 12px 24px;
     font-size: 16px;
     font-weight: 600;
     text-decoration: none;
     background-color: #007bff;
     color: #ffffff;
     border-radius: 8px;
     transition: background-color 0.3s ease;
 } */

.btn {
    /* height: auto; */
    width: auto;
    display: flex;
    text-decoration: none;
}

 /* .button:hover {
     background-color: #0056b3;
 }

 .button:focus {
     outline: none;
     box-shadow: 0 0 0 2px #007bff;
 } */

 .reviewlabel {
     background-color: rgb(141, 141, 141);
     border-radius: 4px;
     padding: 4px 8px;
     color: white;
     font-weight: bold;
 }

 .approvedLabel {
     background-color: green;
     color: white;
 }

 .deniedLabel {
     background-color: red;
     color: white;
 }

.buttonsMaterial {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.denyButton {
    flex-grow: 1;
    margin-right: 10px;
}

.approveButton {
    flex-grow: 1;
    margin-left: 10px;
}

.returnButton {
    width: 100%;
}