.loginText {
  cursor: pointer;
  color: rgba(0, 94, 255, 0.964);
  text-decoration: underline;
 }

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  /* Un fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Asegura que el modal esté por encima de otros elementos */
}

.modalContent {
  background-color: #fff;
  /* Cambia el color de fondo del modal según tus preferencias */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* Agrega una sombra al modal */
  max-width: 400px;
  /* Ajusta el ancho máximo del modal según tus necesidades */
}