.container {
    flex-grow: 1;
    display: block;
}

.carsList {
    background-color: #fff;
    width: 100%;
}

.flexContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.listItem {
    width: 100%;
    padding: 4px 6px;
    flex: 0 0 33%;
    list-style: none;
}

.carItem {
    display: flex;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, .15);
    flex-direction: column;
    transition: all 0.3s;
}

.carItem:hover {
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.308);
}

@media (max-width: 1024px) {
    .listItem {
        flex-direction: column;
        flex: 0 0 50%;
        max-width: 50%;
        flex-grow: 1;
    }
}

@media (max-width: 780px) {
    .listItem {
        flex-direction: column;
        flex: 0 0 100%;
        max-width: 100%;
        flex-grow: 1;
    }
}

.firstBlock {
    height: 100%;
    width: 100%;
}

.firstBlock img {
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: fill;
    display: block;
}

.secondBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.carDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    border-bottom: 0.2px solid #9e9e9e;
}

.carDetails div:last-child {
    font-weight: 600;
    text-align: flex-start;
    font-size: 20px;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.secondBlock ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 5px;
    list-style: none;
    font-size: 14px;
    align-items: flex-start;
}


.secondBlock ul li {
    display: flex;
    gap: 4px;
    list-style: none;
    font-size: 16px;
    align-items: center;
}


.year {
    display: inline-block;
    font-weight: 500;
    background-color: #b5abab1d;
    border-radius: 10px;
    padding: 5px;
    font-size: 13px;
}

.brand {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    height: 30px;
    margin: 5px;
    font-size: 18px;
}

.buttonsRow {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.denyButton {
    flex-basis: 30%;
    /* Tamaño del botón de denegar al 30% */
}

.infoButton {
    flex-grow: 1;
    /* Allow the button to grow and take remaining space */
    margin: 0 8px;
    /* Add desired spacing between buttons */
}

.approveButton {
    flex-basis: 70%;
    height: 60px;
    /* Tamaño del botón de aprobar al 70% */
}

.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centra el contenido horizontalmente */
}

.selectContainer select {
    width: 100%;
    /* Ocupa todo el ancho disponible */
}