/* .container {
    padding-left: 8.25rem;
    padding-right: 8.25rem;
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    max-width: 2120px;
    margin: 0 auto;
} */

.desktopFilter {
    /* height: 100vh; */
    top: 206px;
    /* height: calc(100vh); */
    width: 375px;
    overflow: auto;
    padding: 1rem;
    transition: all 0.2s;
    border-right: 1px solid #9e9e9e69;
}

.disableFilter {
    display: none;
    width: 0px;
}

.container {
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    max-width: 2120px;
    margin: 0 auto;
}


.flexContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    /* Automatically adjust columns */
    grid-gap: 10px;
    /* Gap between grid items */
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    /* Remove default padding */
}

.carListHeader {
    border-bottom: 1px solid #9e9e9e69;
}

.insideContainer {
    height: 100%;
    width: 97%;
    margin: 0 auto;
    display: flex;
}

@media (max-width: 1100px) {

    .container {
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}


@media (max-width: 980px) {
    .insideContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .flexContainer {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}



.title {
    width: 95%;
    margin: 0 auto;
    text-align: start;
    margin-top: 2rem;
    opacity: 0;
    /* Initially hide the title */
    animation: fadeAndMove 1.2s forwards;
    /* Apply the animation */
}

@keyframes fadeAndMove {
    0% {
        opacity: 0;
        transform: translateX(40px);
        /* Start position */
    }

    100% {
        opacity: 1;
        transform: translateX(0);
        /* End position */
    }
}

@media (max-width: 900px) {
    .title {
        margin-top: 1rem;
        margin-bottom: -1rem
    }

    .title h2 {
        font-size: 20px;
    }
}