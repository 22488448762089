.container {
  margin: 0 auto;
  margin-top: 3rem;
  width: 45%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.insideContainer {
  /* background-color: black; */
  height: 100%;
  width: 100%;
  display: flex;
}

.inputBlock input {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 5px;
}

.buttons {
  display: flex;
  gap: 5px;
  flex-direction: row;
  width: 90%;
  height: 45px;
}

.redBtn {
  background-color: #ff3b3b;
  color: #fff;
  border: none;
}


.redBtn:hover {
  border: 1px solid #000;
}

@media (max-width: 780px) {
  .container {
    width: 80%;
  }
}