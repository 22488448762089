.linkApprover {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.linkApprover.approved {
    background-color: #8eff91;
}

.carInfo {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.carTitle {
    margin: 0;
    font-size: 18px;
}

.carId,
.carUuid {
    margin: 0;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.carImage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .carInfo {
        flex-direction: row;
        align-items: flex-start;
    }

    .imageContainer {
        margin-bottom: 0;
        margin-left: auto;
        /* Alinear a la derecha */
        margin-right: 20px;
        /* Agregar espacio a la derecha */
    }
}

.toggle {
    display: flex;
    align-items: center;
    /* Centra el texto y el Toggle Switch */
    margin-right: 20px;
    /* Espacio entre los Toggle Switches */
}