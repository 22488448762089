.container {
    width: 100%;
    padding: 2rem 1rem;
    text-align: start;
    display: flex;
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
}

.container h1 {
    width: 45%;
}

.form {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-left: 1px solid rgb(221, 221, 221);
}

.dropDown {
    justify-content: flex-start;
}

@media (max-width: 780px) {
    .container h1 {
        width: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .form {
        max-width: 600px;
        width: 100%;
        text-align: start;
        display: flex;
        gap: 2rem;
        margin: 0 auto;
        border-left: none;
    }
}