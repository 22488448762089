.carousel {
  position: relative;
  height: 250px;
  /* background: linear-gradient(to right, #414141, #3861fb); */
  overflow: hidden;
}

/* .slider {
  height: 300px;
  position: relative;
} */

/* 
.slider button {
  background-color: #000;
  z-index: 99999999;
  height: 10px;
  width: 10px;
} */

.main {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  padding: 0;
  z-index: 1;
  transition: all 0.3s;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #5e5e5e8e;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.9); */
}

.button:hover {
  background-color: #ffeaea94;
}

.button:first-child {
  left: 10px;
}

.button:last-child {
  right: 10px;
}

.image {
  width: 100%;
  /* height: auto; */
  height: 100%;
  align-self: center;
  background-color: #333A41;
  /* object-fit: scale-down !important; */
  object-fit: cover !important;
}

.imageLink {
  display: inline-block;
  width: 100% !important;
  height: 250px;
}

.counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background-color: #ecececf5;
  color: #000;
  border-radius: 10px;
  height: 2rem;
  width: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.counter:hover {
  transform: translateY(-3px);
}

.counter p {
  font-size: 13px;
}

.btns {
  display: flex;
  position: absolute;
  z-index: 100;
  right: 7px;
  top: 7px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  padding: 0 10px;
  margin-bottom: 5px;
}

.btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1000px;
  background-color: #f8f8f8;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btns button:hover {
  transform: translateY(-2px);

}

.smallBtn {
  cursor: pointer;
}