.container {
    display: block;
    height: 100%;
    width: 100%;
    min-height: 80vh;
}

.content {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.formPosition {
    display: block;
    grid-column: 2/3;
    grid-row: 1/4;
    /* margin-top: 80px; */
    width: 100%;
}

.imgPosition {
    display: block;
    grid-column: 2/3;
    grid-row: 1/4;
    /* margin-top: 80px; */
    width: 150%;
    align-self: center;
    justify-self: center;
}

.featuresPosition {
    display: block;
    grid-column: 1/4;
    grid-row: 1/4;
    width: 60%;
    align-self: center;
    justify-self: center;
}


.carDetailsContainer {
    width: 100%;
    height: 100%;
    grid-column: 1/4;
    grid-row: 1/4;
}


@media (max-width: 1024px) {
    .formPosition {
        grid-column: 1/4;
    }

    .imgPosition {
        grid-column: 1/4;
        width: 100%;
    }

    .featuresPosition {
        width: 95%;
        text-align: start;
    }

    .content {
        grid-template-rows: repeat(3, 1fr);
    }
}


.inputContainer {
    width: 50%;
    min-height: 300px;

    margin: 0 auto;
}

.inputContainer input {
    display: none;
}

.inputContainer label {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid #000;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.smallInputContainer {
    width: 50%;
    margin-top: 20px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}

.smallInputContainer input {
    display: none;
}

.smallInputContainer label {
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: fit-content;
    width: 40%;
    max-width: 500px;
}

.buttonLink {
    text-decoration: none;
}

.centerHeading {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    /* Add any other desired styles for centering and responsiveness */
}

.completed {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2rem auto;
    width: 90%;
    margin-top: 6rem;
    font-weight: bold;
}

@media (max-width: 700px) {
    .buttonContainer {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-bottom: 50px;
    }

    .inputContainer {
        width: 95%;
        min-height: 300px;
        margin: 0 auto;
    }
}