.layer {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.layer:last-child {
    justify-self: end;
}

.container {
    position: absolute;
    width: 100%;
    min-height: 110%;
    top: 0;
    left: 0;
    display: block;
    z-index: 1000;
    background-color: #000000a3;
    backdrop-filter: blur(2px);
    margin: 0 auto;
    margin-top: -100px;
}

.body {
    flex: 1;
}


@media (max-width: 700px) {

    .container {
        position: fixed;
        width: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        display: block;
        background-color: #fff;
        margin: 0 auto;
    }
}