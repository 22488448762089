.container {
    padding: 1rem 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 10000000 !important;
    background-color: #fff;
    overflow: scroll;
    margin-top: 500px;
    transition: all 0.5s;
    overflow: scroll;
}

.containerOpen {
    margin-top: 0px;
}

.header {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0 3rem;
    align-items: center;
    justify-content: flex-end;
}

.bottom {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0 3rem;
    align-items: center;
    justify-content: center;
}

.bottom div {
    width: 60%;
}


.filter {
    /* height: 100%; */
    margin: 0 auto;
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0 5rem 0;
}

.reset {
    width: 3.5rem;
    border: none;
    color: #3861fb;
    font-weight: 600;
    background-color: transparent;
}

.filterContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
}

.filtro {
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    justify-self: flex-end;
    align-items: center;
    background-color: #fff;
    padding: 0px 5px;
    border-left: 2px solid #e4e4e4;
}

.filtroBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #3861fb;
    background-color: #3861fb;
    padding: 5px 10px;
    border-radius: 1000px;
    width: fit-content;
    height: fit-content;
    font-size: 13px;
    color: #fff;
    transition: all 0.3s;
    cursor: pointer;
    z-index: 10;
    gap: 5px;
}

.filtroBtn:hover {
    transform: translateY(-2px);
}

.icon {
    font-size: 18px;
}

.filterOption {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.locationItems {
    display: flex;
    width: 90%;
    margin: 0 auto;
    text-align: flex-start;
    gap: .8rem;
}

.applyFilter {
    background: #3861fb;
    border: none;
    color: #ffffff;
}

.applyFilter:hover {
    background-image: none;
    background-color: #fff;
    border: 2px solid #3861fb;
}

.clearFilter {
    background: #000000;
        border: none;
        color: #ffffff;
}

.clearFilter:hover {
    background-image: none;
    background-color: #fff;
    border: 2px solid #000000;
}

.locationItems div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 45%;
}

.locationItems label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.74);
}

.locationItems select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}


.filterColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    gap: .8rem;
}

.separator {
    display: block;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
}

.filterOption label {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.74);
}

.filterOption select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #dad8d8;
    width: 40%;
}

.filterOption select option {
    margin: 10px;
}

.rangeFilter {

    /* width: 90%;
    display: flex;
    flex-direction: column;
    gap: .8rem; */
    width: 100%;
    background-color: #ffffff;
    /* margin: 10px auto 0 auto; */
    border-radius: 10px;
    min-height: fit-content;
    /* height: 12rem; */
    /* max-height: 13rem; */
    /* position: absolute; */
    z-index: 999;
}

/* .rangeFilter label {
    font-size: 18px;
    font-weight: 600;
    align-self: flex-start;
}

.rangeFilter div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: center;

}

.rangeFilter div input {
    width: 45%;
    padding: .2rem;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}

.rangeFilter div span {
    font-size: 14px;
    font-weight: 500;
} */

.filterList {
    display: flex;
    gap: .8rem;
    overflow: scroll;
    width: 100%;
    min-height: fit-content;
    height: 100%;
    align-items: center;
    overflow-y: hidden;
    padding-left: 15px;
    padding-right: 20px;
    margin-bottom: 0px;
}


.listItem {
    display: flex;
    width: 40px;
    height: 30px;
    min-height: fit-content;
    min-width: fit-content;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.listItem button {
    display: block;
    background-color: transparent;
    border: 1px solid #dad8d8;
    border-radius: 100px;
    padding: 5px 10px;
    font-size: 14px;
    color: #585858;
}

.active button {
    border: 1px solid #3861fb;
    color: #3861fb;
    transform: translateY(-1px);
}

.listItem button:hover {
    background-color: #f0eeee;
    transform: translateY(-1px);
}

.buttonList {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: inherit;
    background-color: #fff;
    height: 65px;
    padding: 10px;
    gap: 5px;
    border-top: 1px solid #dad8d8;
}

.disable {
    display: none !important;
}



@media (max-width: 650px) {
    .filterList {
        width: 100%;
    }

    .title h2 {
        padding: 0 1rem;
    }
}

.colorDisplayer {
    display: block;
    width: 1rem;
    border: 1px solid #dad8d8;
    height: 1rem;
    border-radius: 1000px;
}

.colorItem {
    display: flex;
    align-items: center;
}

.barContainer {
    position: relative;
    width: 100%;
    background-color: #fff;
}

.sticky {
    position: fixed;
    top: 0%;
    z-index: 99999;
}

.filterPreviewContent {
    width: 300px;
    min-width: fit-content;
    background-color: #ffffff;
    margin: 10px auto 0 auto;
    border-radius: 10px;
    min-height: fit-content;
    max-height: fit-content;
    z-index: 999;
}

.filterPreview {
    width: 100%;
    background-color: #000000a3;
    backdrop-filter: blur(2px);
    height: 100vh;
    position: absolute;
    z-index: 999;
    overflow: hidden;
}

.previewContent {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    align-items: center;

}

.previewContent label {
    font-size: 18px;
    font-weight: 600;
    align-self: flex-start;
}

.previewContent div {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: center;

}

.previewContent div input {
    width: 45%;
    padding: .2rem;
    border-radius: 5px;
    border: 1px solid #dad8d8;
}

.previewContent div span {
    font-size: 14px;
    font-weight: 500;
}