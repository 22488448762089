.container {
    flex-grow: 1;
    display: block;
    background-color: #fff;
    width: 100%;
}

.carListLoader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* margin: 2px; */
}

.flexContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    /* Automatically adjust columns */
    grid-gap: 10px;
    /* Gap between grid items */
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    /* Remove default padding */
}

/* Style for grid items (list items) */
.listItem {
    /* padding: 10px; */
    text-align: center;
    /* border: 1px solid #ccc; */
    width: 100%;
    margin: 0 auto;
    padding: 4px 6px;
    list-style: none;
}

.carItem {
    display: flex;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, .15);
    flex-direction: column;
    transition: all 0.3s;
    text-decoration: none;
    overflow: hidden;
}

.carItem:hover {
    box-shadow: 0.125rem 0.125rem 0.4375rem 0 rgba(1, 1, 1, 0.308);
}

@media (max-width: 1550px) {
    .listItem {
        /* min-width: 20rem; */
        max-width: 30rem;
        /* width: 20%; */
        padding: 4px 6px;
        flex: 0 0 25%;
        list-style: none;
    }
}

@media (max-width: 1200px) {
    .listItem {
        max-width: 23rem;
        flex-direction: column;
        flex: 0 0 33%;
        flex-grow: 1;
    }
}

@media (max-width: 550px) {
    .listItem {
        /* width: 100%; */
        flex-direction: column;
        flex: 0 0 80%;
        flex-grow: 1;
    }
}

 
.firstBlock {
    height: 200px;
    width: 100%;
}

.secondBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.carDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0px;
    border-bottom: 0.2px solid #9e9e9e69;
}

.carDetails div:last-child {
    font-weight: 600;
    text-align: flex-start;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.secondBlock ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 5px;
    list-style: none;
    font-size: 14px;
    align-items: flex-start;
    padding-left: 0;
}


.secondBlock ul li {
    display: flex;
    gap: 5px;
    list-style: none;
    font-size: 14px;
    align-items: center;
    padding-left: 10px;
    /* color: #686767; */
}


.year {
    display: inline-block;
    font-weight: 500;
    background-color: #b5abab1d;
    border-radius: 10px;
    padding: 5px;
    font-size: 13px;
}

.brand {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    gap: 8px;
    height: 30px;
    margin: 5px;
    font-size: 15px;
}

.noResults {
    font-weight: bold;
    font-size: 18px;
    /* Puedes ajustar el tamaño según tus preferencias */
}

.resetSearch {
    background-color: #ffffff;
    color: #3861fb;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}

.resetSearch:hover {
    text-decoration: none;
}

.price {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    gap: 8px;
    margin: 5px 0px;
    font-size: 17px;
    border-top: 0.2px solid #9e9e9e69;
}

.label {
    font-size: 15px;
    font-weight: 500;
}

/* CarsForSale.module.css */

.welcomeCard {
    /* Tu estilo existente aquí */
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    position: relative;
    /* Agrega posición relativa para alinear el contenido */
    background: linear-gradient(rgba(246, 246, 246, 0.6), rgba(255, 255, 255, 0.6)), url('../../images/appImages/vcr_logo_only_no_bg.png') center/cover;
    /* Agrega un fondo degradado con transparencia y la imagen de fondo */
}

.welcomeCard h2 {
    font-size: 23px;
    margin-bottom: 10px;
    position: relative;
    /* Añade posición relativa para que el z-index funcione */
    z-index: 1;
    /* Establece un z-index mayor para que el texto esté por encima del fondo */
}

.welcomeCard p {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
    position: relative;
    /* Añade posición relativa para que el z-index funcione */
    z-index: 1;
    /* Establece un z-index mayor para que el texto esté por encima del fondo */
}

.welcomeCard button {
    background-color: #3861fb;
    color: #fff;
    border: none;
    padding: 10px 10px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    /* Añade posición relativa para que el z-index funcione */
    z-index: 1;
    /* Establece un z-index mayor para que el botón esté por encima del fondo */
}

.welcomeCard button:hover {
    background-color: #0056b3;
}
 
.fadeOut {
    opacity: 0;
    /* Set the initial opacity to 0 */
    transition: opacity 0.8s ease;
    /* Apply a transition effect on opacity property */
}