.container {
    padding: 45px;
    display: flex;
    max-width: 1120px;
    margin: 0 auto;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    overflow: hidden;
}

.innerContent {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.title {
    width: 100%;
    text-align: start;
    margin: 3px 0 5px 0;
    display: flex;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.2s ease-out 0.2s forwards;
    /* Agrega una animación */
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        /* Hacia el final de la animación, hace que el título sea visible */
        transform: translateY(0);
        /* Mueve el título a su posición original */
    }
}


.imgContainer {
    position: relative;
    height: 400px;
    width: 100%;
    display: block;
    margin: 0rem auto 0.4rem auto;
    border-radius: 5px;
}

.imgContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.smallContainer {
    display: block;
    /* border: 1px solid rgba(0, 0, 0, 0.185); */
    /* border-radius: 5px; */
    border-top: 1px solid #cacaca;
    width: 100%;
    padding: 5px;
    padding-left: 0.5rem;
    text-align: start;
}

.smallContainer h4 {
    margin-bottom: .5rem;
    font-size: 16px;
    font-weight: 400;
    color: #494949;
}

.smallContainer p {
    font-size: 22px;
    font-weight: 600;
}


.smallContainer ul {
    padding-left: 0px;
    list-style: none;
    text-align: start;

}

.priceContainer {
    display: block;
    border-top: 1px solid #cacaca;
    /* border: 1px solid rgba(0, 0, 0, 0.185); */
    /* border-radius: 5px; */
    width: 100%;
    padding: 5px;
    padding-left: 0.5rem;
    text-align: start;
}

.contact {
    display: block;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    height: 205px;
    width: 50%;
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    ;
}

.contact span {
    font-size: 14px;
    color: #494949;
    font-weight: 300px;
}


.containerNotFound {
    padding: 45px;
    display: flex;
    max-width: 1120px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.contentNotFound {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
}

.titleNotFound {
    width: 100%;
    text-align: center;
    margin: 5px 0 20px 0;
    font-size: 42px;
    font-weight: bold;
}

/* Estilos comunes para ambos tamaños de pantalla */
.imgContainerNotFound {
    height: auto;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
}

.imgContainerNotFound img {
    border-radius: 5px;
}

/* Estilos específicos para escritorio (ancho de pantalla mayor a 768px) */
@media (min-width: 769px) {
    .imgContainerNotFound img {
        max-width: 40%;
        /* Ajusta el porcentaje según tus necesidades */
        max-height: 40%;
        /* Ajusta el porcentaje según tus necesidades */
        object-fit: cover;
    }
}

/* Estilos específicos para dispositivos móviles (ancho de pantalla hasta 768px) */
@media (max-width: 768px) {
    .imgContainerNotFound img {
        max-width: 60%;
        /* Ajusta el porcentaje según tus necesidades */
        max-height: 60%;
        /* Ajusta el porcentaje según tus necesidades */
        object-fit: cover;
    }
}


.btn {
    /* height: auto; */
    width: auto;
    display: flex;
    text-decoration: none;
}

.btnWhatsapp {
    background-color: #3861fb;
    border: 1px solid transparent;
    /* Borde transparente por defecto */
    color: #fff;
    /* Texto blanco por defecto */
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    /* Agrega la transición para el cambio de color del borde */
}

.btnWhatsapp:hover {
    background-color: #fff;
    /* Cambiamos el color de fondo a blanco al hacer hover */
    color: #3861fb;
    /* Cambiamos el color del texto al color del fondo original */
    border-color: #3861fb;
    /* Cambiamos el color del borde al color del fondo original */
}

.btnLlamar {
    background-color: #000;
    border: 1px solid transparent;
    /* Borde transparente por defecto */
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    /* Agrega la transición para el cambio de color del borde */
}

.btnLlamar:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
    /* Cambia el color del borde al hacer hover */
}

.btnPhone {
    background-color: #4267B2;
    border: none;
}


@media (max-width: 700px) {

    .container {
        padding: 10px;
    }

    .innerContent {
        flex-direction: column-reverse;
    }

    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        height: 65px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        border-top: 1px solid #cacaca;
        /* (Caca jaja) */
        color: #fff;
        background-color: #fff;
        overflow: hidden;
        z-index: 10;
    }

    .text {
        display: none;
    }

    .buttonGroup {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        z-index: inherit;
        background-color: #fff;
        height: 65px;
        padding: 10px;
        gap: 5px;
        border-top: 1px solid #dad8d8;
    }

    .contact span {
        color: #fff
    }

    .contact:last-child {
        display: flex;
    }

    .imgContainer {
        height: 250px;
        margin: 0rem
    }

    .title {
        margin: 5px 0 0px 0;
    }



}

.btns {
    align-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.btns button {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;

}

.btnWhatsappWrapper:hover {
    background-color: white;
}


.skeletonMobile {
    margin: 10px;
    margin-top: 20px;
}

.year {
    display: inline-block;
    font-weight: 400;
    background-color: #938b8b1d;
    border-radius: 5px;
    margin-right: 0.4rem;
    padding: 3px 8px;
}