.content {
    margin: 20px;
    display: flex;
    /* flex-direction: row; */
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: fit-content;
}

.btn {
    max-width: 10rem !important;
    margin: 0 auto;
}

.textArea {
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
    margin-top: 3rem;
}

.login {
    width: 100%;
}

.characterCounter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #808080;
    margin-top: 4px;
}

.feature {
    border-bottom: 1px solid #efefef;
    justify-content: space-between;
    align-self: center;
    justify-items: center;
    width: 100%;
    margin: 0 !important;
    flex: 40%;
}

@media (max-width: 1024px) {
    .content {
        display: grid;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;

    }

    .characterCounter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #808080;
        margin-top: 4px;
    }
}

@media (max-width: 700px) {
    .content {
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;
        display: flex;
        flex-direction: column;
        /* flex-direction: row; */
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        min-width: none;
        width: 100%;
    }

    .textArea {
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        margin-top: 3rem;
    }
}