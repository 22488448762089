.headBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    color: #000;
    height: 50px;
    margin: 0;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 34px 1px rgba(0, 0, 0, 0.1);

}

.menu {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 15px;
    margin-top: auto;
    flex-direction: row nowrap;
    text-decoration: none;
    align-items: center;
}


.app_bar {
    /* position: fixed;
    top: 0;
    left: 0; */
    /* width: 100%; */
    /* z-index: 999; */
    background-color: #fff;
    color: #000;
    height: 60px;
    /* margin: 0;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center; */
    box-shadow: 0px 0px 34px 1px rgba(0, 0, 0, 0.1);
}

.toolbar {
    display: flex;
    background-color: #fff;
    color: #000;
    height: 60px;
    justify-content: space-between;
    box-shadow: 0px 0px 34px 1px rgba(0, 0, 0, 0.1);
}

.menudropdown {
    text-transform: capitalize !important;
    color: #000 !important;
    font-size: 15px !important;
    display: flex;
    align-items: center;
    gap: 5px !important;
}

h1 {
    margin: 0
}

.parentItem {
    display: flex;
    flex-direction: row;
    margin: 0 !important;
    width: 90%;
    border-bottom: 1px solid #efefef;
    padding: 5px;
    /* align-items: center;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    gap: 10px;
    width: 90%;
    margin-bottom: 10px; */
}

.link {
    color: #000;
    text-decoration: none;
    margin-right: 2px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    min-width: fit-content;
    height: 100%;
}

.logo {
    max-height: 50px;
    width: 200px;
}

.logo img {
    height: 100%;
    overflow: hidden;
    /* width: 100%; */
    max-height: 50px;
    object-fit: cover;
}

.icon {
    width: 100%;
    display: inline-block;
    height: 30px;
    color: #000;
    text-align: end;
    margin-right: 5px;
    font-weight: 500;
    cursor: pointer;
}


.enVenta {
    border: none;
    border-radius: 5px;
    min-width: fit-content;
    padding: 5px 10px;

    background-color: #3861fb;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    height: 80%;
    border: 2px solid transparent;
    overflow: hidden;

    text-overflow: ellipsis;
}

.enVenta:hover {
    background-color: #fff;
    color: #3861fb;
    border: 2px solid #3861fb;
}

@media (max-width: 1024px) {
    .formPosition {
        grid-column: 1/4;
    }
}

.logo {
    height: 80%;
    width: 150px;
    object-fit: cover;
}

.nav_top {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;

    justify-content: space-between;
    margin: 0 auto !important;
}

.header_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.header_list div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    gap: 10px;
    width: 90%;
    margin-bottom: 10px;
}

.login {
    background: #3861fb;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), rgb(239, 242, 245); */
    color: rgb(255, 255, 255);
    text-decoration: none;
    border-radius: 5px;
    width: 90%;
    font-weight: 500;
    margin: 1rem auto;
    cursor: pointer;
    padding: 8px 10px;
    transition: all 0.2s;
}

.login:hover {
    background-color: #2948b7;
    color: #FFFF;
}


@media (max-width: 700px) {
    .link {
        text-decoration: none;
        margin-right: 0;
        font-size: 17px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        min-height: 2rem;
        height: 100%;
        width: 100%;
        padding: 5px 10px;
        padding-left: 20px;
        cursor: pointer;
    }
}