.button {
    color: #fff;
    background-color: #1d1d1f;
    height: 100%;
    min-height: fit-content;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 20px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    transition: .3s ease-in-out, color .3s ease-in-out;
}

.button:hover {
    color: #1d1d1f !important;
    background-color: #fff;

}

.whatsappBtn {
    color: #fff;
    text-decoration: none;
}

.whatsappBtn:hover {
    color: #000;
}

.btnDisable {
    background-color: #cfcfcf;
    border: none;
}

.btnDisable:hover {
    color: #fff !important;
    background-color: #cfcfcf !important;
}