.imgContainer {
    height: 300px;
    width: 100%;
    margin: 0 auto;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.smallImgContainer {
    height: 200px;
    width: 100%;
    margin: 0 auto;
}

.smallImgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.bigContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}