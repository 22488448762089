.container {
    flex-grow: 1;
    display: block;
    margin: .4rem 0;
}

.carsList {
    display: flex;
    width: 100%;
    border-bottom: 0.2px solid #9e9e9e;
    justify-content: space-between;
    align-items: center;
}


.carDetails {
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.secondBlock ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 5px;
    list-style: none;
    font-size: 14px;
    align-items: flex-start;
}


.secondBlock ul li {
    display: flex;
    gap: 4px;
    list-style: none;
    font-size: 14px;
    align-items: center;
}


.year {
    display: inline-block;
    font-weight: 500;
    background-color: #b5abab1d;
    border-radius: 10px;
    padding: 5px;
    font-size: 13px;
}

.brand {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    height: 30px;
    margin: 5px;
    font-size: 18px;
}