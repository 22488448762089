.imgContainer {
    width: 100%;
    display: block;
    position: relative;
    height: 30rem;
}

.imgContainer div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30rem;
    z-index: 10;
}

.header {
    height: 80vh;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerContainer {
    height: 500px;
    width: 45% !important;
    max-width: 650px;
    gap: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 100;
    background-color: #fff;
    padding: 1rem;
}

.imageContainer {
    height: 60%;
    border-radius: 15px;
    overflow: hidden;
    justify-self: flex-end;
}

.headerBtn {
    width: 80%;
    display: flex;
    height: 3rem;
    gap: 10px;
}

.title {
    color: #000;
    width: 45%;
    min-width: 500px;
    text-align: start;
    font-size: 55px;
    font-weight: bold;
}

.headerContainer p {
    text-align: start;
    font-size: 18px;
}

.callToActionCarrosEnVenta {
    background-color: #000;
    /* Color de fondo negro */
    border: 1px solid transparent;
    /* Borde transparente por defecto */
    color: #fff;
    /* Color del texto blanco */
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    /* Transición suave para el cambio de color */
    padding: 8px 16px;
    border-radius: 5px;
}

.callToActionCarrosEnVenta:hover {
    background-color: #fff;
    /* Cambia el fondo a blanco */
    color: #000;
    /* Cambia el color del texto a negro */
    border-color: #000;
    /* Cambia el color del borde a negro */
}


.callToActionEmpezar {
    background-color: #000;
    border: none;
    padding: 1.4rem 7rem;
    /* Aumenta el padding para hacer el botón más grande */
    color: #fff;
    transition: color 0.3s, background-color 0.3s;
    border-radius: 5px;
}

.callToActionEmpezar:hover {
    background-color: #fff;
    padding: 1.4rem 7rem;
    /* Asegúrate de que el padding sea consistente */
    color: #000;
    border: 1px solid #000;
}



.callToActionBtn {
    background: #3861fb;
    /* background-image: linear-gradient(to right bottom, #3861fb, #0093ff, #00b6ff, #00d3f0, #12ebd4); */
    border: 2px solid transparent;
    /* Borde transparente por defecto */
    color: #ffffff;
    transition: border-color 0.3s, background-color 0.3s;
    /* Transición suave para el cambio de color */
}

.callToActionBtn:hover {
    background-image: none;
    background-color: #fff;
    border-color: #3861fb;
    /* Cambia el color del borde a #3861fb */
}


.img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.firstSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    background-color: #141414;
    padding: 6rem 0 6rem 0;
}

.smallBtn {
    padding: 8px 18px;
    border-radius: 30px;
    background-color: #fff;
}

.information {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
}

.infoItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 25%;
    border-radius: 5px;
    padding: 25px 15px;
    color: #fff;
}

.infoItem p {
    font-size: 18px;
}

.infoItem h4 {
    font-size: 22px;
}

.icon {
    font-size: 40px;
    color: #3861fb;
}

.secondSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.form {
    width: 65%;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #f7f7f7;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 0px 34px 1px rgb(0 0 0 / 10%)
}

.input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.dealerSection {
    box-shadow: 0 0 10px rgba(31, 31, 31, 0.219);
    width: 70%;
    padding: 2rem;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    border-radius: 10px;
}

.dealerText {
    text-align: start;
    padding: 1rem;
    width: 70%;
}

.showBtn {
    background-color: transparent;
    border: none;
    grid-column: 2/4;
    align-self: center;
    justify-self: center;
    border-radius: 30px;
    background-color: #000;
    color: #fff;
    padding: 8px 18px;
}

.logoSection {
    height: fit-content;
    padding: 4rem 1rem;
}



.logoList {
    width: fit-content;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(4, 200px);
    gap: 1rem;
}

.logoItem {
    width: 200px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;
    box-shadow: 0 0 8px rgba(78, 78, 78, 0.219);
}

.logoItem img {
    height: 75px;
    width: 75px;
    object-fit: contain;
    object-position: center;
}

.logoItem p {
    font-weight: bold;
}

.lastItem {
    font-weight: bold;
}


.btn {
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    min-width: 200px;
    padding: .8rem .4rem;
}

@media (max-width: 1150px) {
    .logoList {
        grid-template-columns: repeat(3, 1fr);
    }

    .header {
        height: fit-content;
        flex-direction: column;
        width: 100%;
        align-items: start;
    }

    .headerContainer {
        /* flex: 80%; */
        text-align: center;
        width: 90% !important;

    }

    .imageContainer {
        /* flex: 20%; */
        height: 80%;
        border-radius: 15px;
        padding: 1rem;
    }

    .showBtn {
        grid-column: 2/3;
    }
}

@media (max-width: 800px) {
    .headerBtn {
        width: 100%;
    }

    .showBtn {
        grid-column: 1/3;
    }

    .dealerSection {
        width: 95%;
        flex-direction: column;
    }

    .logoList {
        grid-template-columns: repeat(2, 1fr);
    }

    .headerContainer {
        /* height: 400px; */
        height: 22rem;
        width: 100% !important;
    }

    .headerContainer p {
        font-size: 15px;
    }

    .dealerText {
        width: 95%;
    }



    .logoList {
        grid-template-columns: repeat(2, 150px);
    }

    .logoItem {
        width: 100%;
    }

    .secondSection {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .information {
        /* flex-direction: row; */
        flex: row nowrap;
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
        justify-content: start;
    }

    .infoItem {
        min-width: 80%;
        background-color: #303030;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 25%;
        border-radius: 5px;
        padding: 25px 15px;
        color: #fff;
    }

    .form {
        width: 90%;
    }

    .title {
        font-size: 35px;
        text-align: start;
        padding: 10px;
        font-weight: 600;
        min-width: fit-content;
    }

}


@media (max-width: 600px) {
    .imgContainer div {

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40rem;
        z-index: 10;
    }

    .title {
        font-size: 35px;
        text-align: start;
        padding: 10px;
        font-weight: 600;
        min-width: fit-content;
    }

    .infoItem {
        background-color: #363636;
        min-width: 80%;
    }

    .infoItem p {
        font-size: 15px;
    }

    .infoItem h4 {
        font-size: 19px;
    }


}

.secondTitle {
    text-align: center;
    margin-bottom: 20px;
    color: white;
}

.redesSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom right, #636363, #1e1e1e);
    padding: 20px 0;
}

@keyframes fondoAnimado {
    0% {
        background-color: #000;
        /* Inicia en negro */
    }

    50% {
        background-color: #333;
        /* Cambia a un tono de gris */
    }

    100% {
        background-color: #000;
        /* Vuelve a negro al final */
    }
}




.redesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.redesButton {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #00b7cb;
    color: #fff;
    border: 1px solid transparent;
    /* Cambiado de "border: none;" */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    flex-grow: 1;
    /* Hacer que los botones ocupen el mismo espacio */
    min-width: 140px;
    /* Establecer un ancho mínimo para los botones */
    max-width: 200px;
    /* Máximo ancho para evitar que los botones se estiren demasiado */
    text-align: center;
    /* Alinear el texto al centro */
}

.redesButton:hover {
    background-color: #fff;
    color: #000;
    border-color: #000;
    /* Cambiado de "border: 1px solid #000;" */
}


.instagramButton {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #C13584;
    
    /* Color de Instagram */
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    flex-grow: 1;
    min-width: 140px;
    max-width: 200px;
    text-align: center;
}

.instagramButton:hover {
    background-color: #fff;
    
    /* Cambio de color al hacer hover */
    color: #C13584;
    /* Cambio de color del texto al hacer hover */
    border-color: #C13584;
    /* Cambio de color del borde al hacer hover */
}


/* Estilo para el botón de WhatsApp */
.whatsappButton {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #075e54;
    /* Color de WhatsApp */
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    flex-grow: 1;
    min-width: 140px;
    max-width: 200px;
    text-align: center;
}

.whatsappButton:hover {
    background-color: #fff;
    /* Cambio de color al hacer hover */
    color: #075e54;
    /* Cambio de color del texto al hacer hover */
    border-color: #075e54;
    /* Cambio de color del borde al hacer hover */
}

/* Estilo para el botón de Facebook */
.facebookButton {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #1877F2;
    /* Color de Facebook */
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    flex-grow: 1;
    min-width: 140px;
    max-width: 200px;
    text-align: center;
}

.facebookButton:hover {
    background-color: #fff;
    /* Cambio de color al hacer hover */
    color: #1877F2;
    /* Cambio de color del texto al hacer hover */
    border-color: #1877F2;
    /* Cambio de color del borde al hacer hover */
}

/* Estilo para el botón de AutosNova */
.autosNovaButton {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #000;
    /* Color Negro */
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    flex-grow: 1;
    min-width: 140px;
    max-width: 200px;
    text-align: center;
}

.autosNovaButton:hover {
    background-color: #fff;
    /* Cambio de color al hacer hover */
    color: #000;
    /* Cambio de color del texto al hacer hover */
    border-color: #000;
    /* Cambio de color del borde al hacer hover */
}