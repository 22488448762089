.container {
    width: 100%;
    min-height: 100vh;
}

.formContainer {
    margin: 0 auto;
    width: 40%;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
    text-align: start;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    margin-bottom: 10px;
}

.row div {
    width: 100%;
}

.scheduleDay {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}


.scheduleDay div {
    width: 80%;
}

.checkbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 13px;
}

.checkbox label {
    font-size: 14px !important;
}

.checkboxInput {
    margin: 5px;
    width: 2rem !important;
}

@media (max-width: 1150px) {
    .formContainer {
        width: 80%;
    }

    .row {
        flex-direction: column;
        gap: 4px;
    }

}