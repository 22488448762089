.header {
    display: flex;
    width: 95%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.firstElement {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.firstElement span {
    font-size: 15px;
    color: #676767;
    font-weight: 400;
}

.filterBtn {
    border: 1px solid #dad8d8;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    width: 2.5rem;
    height: 2.5rem;
    color: #333;
    transition: all 0.2s;
}

.filterBtn:hover {
    background-color: rgba(18, 18, 18, 0.075);
}

.selectContainer {
    width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem;
    gap: .2rem;
}

.selectButton {
    border: 1px solid #dad8d8;
    background-color: white;
    border-radius: 8px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    color: #333;
}

.selectButton span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
}

.rotate {
    transform: rotate(180deg);
}

.dropDown {
    width: 16rem;
    border: 1px solid #dad8d8;
    background-color: white;
    border-radius: 8px;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    transition: all 2s;
    position: absolute;
    margin-top: 2.8rem;
    z-index: 9999;
}

.dropDown li {
    padding: 1rem;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    height: auto;
}

.dropDown li:hover {
    background-color: #f0f0f0c4;
    align-self: flex-start;
}

@media (max-width: 500px) {

    .firstElement span {
        font-size: 15px;
        color: #676767;
        font-weight: 400;
    }

    .selectContainer {
        width: 13rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: .5rem;
        gap: .2rem;
        overflow: hidden;
        font-size: 13px;
    }


    .selectButton span {
        font-size: 13px;
    }

    .dropDown {
        width: fit-content;
        margin-top: 2.6rem;
        font-size: 13px;
    }


    .dropDown li {
        padding: 13px;
    }


}