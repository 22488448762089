.header {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 5rem;
}

.header h2 {
    color: #000;
}

.header p {
    color: #000;
}



.headerName {
    width: 100%;
    padding: 2rem 3rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headerInfo {
    width: 100%;
    padding: 6rem 3rem;
    text-align: end;
}

.headerInfo p {
    font-size: 16px;
}

.header h1 {
    color: #000;
    margin-bottom: 6px;
    font-size: 35px !important;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: start;
    /* border-bottom: 1px solid #cacaca; */
    flex-grow: 1;
    padding: 2rem 1rem;
    margin: 0 auto;
    max-width: 1300px;
}

.title {
    align-self: flex-start;
    font-size: 16px;
    margin-bottom: 10px;
    width: fit-content;
}

.hiddenBtn {
    border: none;
    background-color: transparent;
    width: fit-content;
    color: #000;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1100px;
    min-width: 70%;
    margin: 0 auto;
    padding: 4rem 2rem 0rem 2rem;
}

.location {
    width: 100%;
    text-align: start;
    display: flex;
    padding-top: 2rem;
    flex-direction: row;
    border-top: 1px solid rgb(221, 221, 221);
}

.itemsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.profileItems {
    font-size: 15px;
    border-bottom: 1px solid rgb(221, 221, 221);
    font-weight: 400;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}

.leftColumn {
    width: 50%;
}

.leftColumn ul {
    list-style: none;
    text-align: start;
}


.leftColumn ul li span {
    font-weight: bold;
    font-size: 15px;
}

.map {
    width: 50%;
}

.contact {
    width: 70%;
    text-align: start;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(221, 221, 221);
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px; */
    border-radius: 5px;
    padding: 2rem 3rem;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    gap: 2rem;
}


.contact:last-child {
    display: flex;
    flex-direction: column;
}

.row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: start;
    justify-content: space-between;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.item p {
    font-weight: 600;
    font-size: 17px;
}


.links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #000;
}

.links a {
    border-bottom: 2px solid #000;
    width: fit-content;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.span {
    font-size: 16px;
}

.flexContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 10px;
    list-style: none;
    padding: 0;
}


.listItem {
    /* padding: 10px; */
    text-align: center;
    /* border: 1px solid #ccc; */
    width: 100%;
    margin: 0 auto;
    padding: 4px 6px;
    list-style: none;
}

.scheduleContainer {
    min-width: 300px;
    margin: 0 auto;
}

.scheduleItem {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    color: #000;
    border-bottom: 1px solid rgb(221, 221, 221);
    font-weight: 400;
}

.scheduleItem span {
    font-size: 14px;
}


.scheduleItem p {
    font-weight: bold;
    font-size: 14px;
}

.dealerInfo {
    width: 100%;
    margin: 0 auto;
    padding: 15px 5px;
    word-break: break-all;
}

.dealerDetails {
    /* width: 40%; */
}

.text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
}

text p {
    text-align: start;
}

.callToActionBtn {
    max-width: fit-content;
}

.verCatalogo {
    width: 100%;
    /* Establecer el ancho al 100% */
    box-sizing: border-box;
    /* Incluir padding y borde en el ancho */
}

.reviewList {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
}

.profilePic {
    position: absolute;
    bottom: -6%;
    left: 50%;
    transform: translateX(-50%);
}

.cover {
    background: linear-gradient(45deg, #004aad 0%, #30b5f8 100%);
    width: 100%;
    height: 100%;
}

.icons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    font-size: 20px;
}

@media (max-width: 1550px) {
    .listItem {
        /* min-width: 20rem; */
        max-width: 30rem;
        /* width: 20%; */
        padding: 4px 6px;
        flex: 0 0 25%;
        list-style: none;
    }
}

@media (max-width: 1150px) {
    .listItem {
        max-width: 20rem;
        flex-direction: column;
        flex: 0 0 33%;
        flex-grow: 1;
        flex-wrap: wrap;
    }




    .container {
        /* margin: 4rem 6rem; */
        flex-grow: 1;
        display: block;
    }
}

@media (max-width: 780px) {
    .flexContainer {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    /* .header {
        height: fit-content;
        flex-direction: column;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        text-align: start;
        width: 100%;
        padding: 1rem;
    } */

    .header h1 {
        color: #000;
        margin-bottom: 1rem;
        font-size: 40px !important;
    }

    .headerName {
        padding: 1rem;
    }

    .headerInfo {
        padding: 1rem;
        text-align: start;
    }

    .dealerInfo {
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
    }

    .dealerDetails {
        width: 100%;
    }

    .itemsList {
        width: 100%;
    }

    .contact {
        width: 80%;
        flex-direction: column;
    }

    .row {
        flex-direction: column;
    }


    .location {
        width: 80%;
        margin: 0 auto;
        text-align: start;
        display: flex;
        flex-direction: column;
    }

    .leftColumn {
        width: 100%;
    }


    .leftColumn ul {
        text-align: start;
    }

    .profileItems {
        display: flex;
        align-items: self-start;
        flex-direction: column;
        font-size: 12px;
    }

    .profileItems span {
        font-weight: 600;
        font-size: 15px;
    }


    .map {
        width: 100%;
    }

    .detailsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 0;
    }

    .detailsContainer div {
        width: 100%;
        margin: 0 auto;
    }
}