.container {
    padding-left: 8.25rem;
    padding-right: 8.25rem;
    display: block;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    max-width: 2120px;
    margin: 0 auto;
}

.insideContainer {
    height: 100%;
    width: 100%;
    display: flex;
}

@media (max-width: 980px) {
    .insideContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.title {
    width: 85%;
    text-align: start;
    /* margin: 0 auto; */
    margin-top: 2rem;
    opacity: 0;
    animation: fadeAndMove 1.2s forwards;
}

@keyframes fadeAndMove {
    0% {
        opacity: 0;
        transform: translateX(40px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.accordion {
    margin-bottom: 1rem;
    padding: 1rem;
    /* Add padding to create space when closed */
}

.question {
    cursor: pointer;
    background-color: #f4f4f4;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    font-size: 1.5rem;
}

.answer {
    background-color: #ffffff;
    padding: 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: block;
    margin-top: 1rem;
    /* Add margin at the top */
    margin-bottom: 4rem;
    /* Add margin at the bottom */
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.accordionWrapper {
    margin-bottom: 1rem;
    /* Add spacing between accordions when closed */
}

.accordion.active .answer {
    max-height: 1000px;
    /* Adjust as needed */
}

.boldQuestion {
    font-weight: bold;
}

.justifyAnswer {
    text-align: justify;
}

.section {
    text-align: justify;
    padding: 1rem;
    font-size: 16px;
    line-height: 1.5;
    margin: 1rem 0;
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 1rem 0;
}

.goBackButton {
    background-color: #000;
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    /* font-weight: bold; */
    transition: all 0.3s;
    border-radius: 5px;
}

.goBackButton:hover {
    background-color: #0000007c;
}

.link {
    text-decoration: none !important;
    /* Remove underline */
    color: inherit !important;
    /* Inherit color from parent element */
}