.card {
    width: 48%;
    height: 350px;
    min-width: 500px;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    padding: 4rem;
    position: relative;
}

.card img {
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    filter: brightness(40%);
    height: 100%;
    object-fit: cover;
}

.modal {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: flex-start;
    gap: 1.5rem;
}

.title {
    color: #fff;
    font-weight: 700;

}

.text {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.btn {
    color: #000;
    background-color: #fff;
    height: 2.5rem;
    width: 8rem;
    font-size: 15px;
    font-weight: 500;
    border-radius: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    transition: .3s ease-in-out, color .3s ease-in-out;
    text-decoration: none !important;
}

@media (max-width: 800px) {
    .card {
        width: fit-content;
        height: 350px;
        min-width: fit-content;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        /* padding: 4rem; */
        position: relative;
    }
}