.post {
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1.5rem;
    align-items: center;
    text-align: start;
}

.title {
    display: flex;
    justify-content: flex-start;
    max-width: 60rem;
    width: 60%;
}

.container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table {
    width: 60%;
    text-align: start;
    /* background-color: #2f2f2f; */
    border-radius: 5px;
}

tr {
    border-bottom: 1px solid #969696;
    height: 2rem;
    color: #000;
    border-radius: 5px;
}


.rowContent {
    display: inline-block;
    padding: 10px 2rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.rowContent label {
    font-weight: 500;
    min-width: fit-content;
    width: 12rem;

}

.chip {
    margin: 0 auto;
    min-width: fit-content;
    width: 60%;
}

.noteContainer {
    width: 400px;
    max-width: 90%;
}

.rowContent input {
    border: none;
    min-width: fit-content;
    width: 10rem;
    background-color: #b4b4b425;
    border-radius: 5px;
    color: #2f2f2f;
    padding: 5px 8px;
}

.rowContent input:disabled {
    background-color: #cfcfcf;
    font-weight: 500;
}

.checkbox {
    display: flex;
    flex-direction: column;
    font-weight: 13px;
}

.checkboxInput {
    width: 5rem !important;

}

.dropDown {
    margin: 0 0;
}

.icon {
    justify-self: flex-end;
    font-size: 18px;
}

.btn {
    background-color: #d44646;
    border: 1px solid #d44646;
}

.btnGroup {
    width: fit-content;
    display: flex;
    gap: 8px;
    width: 50%;
    align-items: center;
    justify-content: center;
}

@media (max-width: 700px) {
    .btnGroup {
        flex-wrap: wrap;
    }

    .table {
        width: 100%;
        text-align: start;
        overflow: hidden;
    }


    .rowContent {
        /* align-items: flex-start; */
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        width: 100%;
    }

    .rowContent label {
        font-weight: 500;
        min-width: fit-content;
        width: auto;

    }

    .title {
        width: 90%;
    }

    .chip {
        width: 95%;
    }

}