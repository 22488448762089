.container {
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    overflow: scroll;
    height: 100%;
    z-index: 1200;
}

.grid {
    display: grid !important;
    gap: 8px !important;
    grid-template-columns: 1fr !important;
    padding: 1rem;
}

.image {
    width: auto;
}

.row {
    background-color: #fff;
    height: 2rem;
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}