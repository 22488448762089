.row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.btn {
    width: 6rem;
    margin-bottom: 1rem;
}


.firstSection {
    grid-column-start: 1;
    grid-column-end: 4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0px 1rem 0px;
    margin: 0 auto;
    gap: 2rem;
}

.stepsInstructions {

    text-align: initial;
}

.information {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 4rem;
}

.infoItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 10rem;
    border-radius: 10px;
}

.icon {
    font-size: 30px;
    color: #000;
}

@media (max-width: 750px) {
    .information {
        flex-direction: column;
        align-items: center;
    }
}