.container {
    background-color: #0f0f0fa4;
    width: calc(100vw - 20px);
    left: 0;
    border: 2px solid green;
    height: 10000px;
    margin-top: -170px;
}

.main {
    width: 100%;
    height: 100%;
    position: inherit;
    display: flex;
    align-items: center;
    background-color: #0f0f0f;
}

.imgContainer {
    display: flex;
    align-items: center;
    height: fit-content;
    min-width: 350px;
    width: fit-content;
    /* max-width: 800px; */
    border-radius: 15px;
    width: auto;
    /* min-width: 800px; */
    width: fit-content;
    max-width: 1100px;
    /* Adjust the width as needed */
    height: fit-content;
    min-height: 500px;
    max-height: 800px;
    /* Optional background color */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
    padding: 0;
    z-index: 1;
    transition: all 0.3s;
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: #0000008c;
    z-index: 1201;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.9); */
}

.button:hover {
    background-color: #ffeaea94;
}

.button:first-child {
    left: 5rem;
}

.button:last-child {
    right: 5rem;
}

.image {
    border-radius: 5px;
    width: 100%;
    /* height: auto; */
    height: 100%;
    object-fit: contain;
}

.imageLink {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.closeBtn {
    color: #fff;
    font-size: 20px;
    display: block;
    transition: 0.3s all;
    padding: 5px 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.closeBtn:hover {
    background-color: #363636;
    border-radius: 5px;

}

.counter {
    color: #fff;
}


.row {
    position: fixed;
    top: 10%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}