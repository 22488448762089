.gitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.gitInfo {
  text-align: center;
}

.gitVersion {
  font-size: 40px;
  font-weight: bold;
  animation: colorChange 10s infinite;
}

.textFace {
  font-size: 50px;
  font-weight: bold;
  animation: colorChange 20s infinite;
}

@keyframes colorChange {
  0% {
    color: red;
  }

  50% {
    color: rgb(174, 0, 255);
  }

  100% {
    color: rgb(255, 149, 0);
  }
}