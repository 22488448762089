.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    overflow: hidden;
    border-radius: 10px;
    padding: 10px 15px;
    margin: 1.5rem 0rem;
    padding: 1rem;
}

.container p {
    width: 100%;
    word-wrap: break-word;
}

.item {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}


.item p {
    width: fit-content;
}


.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.reviewItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(221, 221, 221);
    width: 250px;
}

.reviewItem span {
    width: fit-content;
    font-weight: bold;
    font-size: 15px;
}

.reviewItem p {
    width: fit-content;
}

.reviews {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}



@media (max-width: 750px) {


    .reviewItem {
        width: 100%;
    }
}