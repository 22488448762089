.linkApprover {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
    /* Transición suave durante 0.3 segundos */
}

.linkApprover.approved {
    background-color: #8eff91;
    /* Cambia al color verde de aprobación */
}

.carInfo {
    margin-bottom: 20px;
}

.carTitle {
    margin: 0;
    font-size: 18px;
}

.carId,
.carUuid {
    margin: 0;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.carImage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* Agrega una sombra ligera */
}

.checkboxes {
    display: flex;
    justify-content: center;
    /* Centra los Toggle Switches */
    margin-bottom: 20px;
        justify-content: space-between;
    /* Incrementa el espacio entre los Toggle Switches y Canva */
}

.toggle {
    display: flex;
    align-items: center;
    /* Centra el texto y el Toggle Switch */
    margin-right: 20px;
    /* Espacio entre los Toggle Switches */
}

.canvaLink {
    display: block;
    margin-top: 20px;
    /* Incrementa el espacio entre los Toggle Switches y Canva */
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    /* Espacio uniforme entre los botones */
    margin-top: 20px;
    /* Espacio entre los botones y Canva */
}

.linkButton {
    text-decoration: none;
    margin: 0 10px;
    /* Espacio entre los botones */
}